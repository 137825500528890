"use client";

import { createContext, ReactNode, useEffect, useState } from "react";

import { User, UserImpl } from "@utils/User/User";
import { CustomerData } from "@utils/Customer/Customer";
import { RetailerData } from "@utils/Retailer/RetailerRepository";
import { SessionPayload } from "@db/sessions";

export interface UserContextInterface {
  user: User | null;
  sessionPayload: SessionPayload | null;
  setSessionPayload: (sessionPayload: SessionPayload | null) => void;
}

export const UserContext = createContext<UserContextInterface>({} as UserContextInterface);

export const UserProvider = ({
  children,
  sessionData
}: {
  children: ReactNode;
  sessionData?: SessionPayload | null;
}) => {
  // User is the name of the "data" that gets stored in context
  const [user, setUser] = useState<User | null>(null);
  const [sessionPayload, setSessionPayload] = useState<SessionPayload | null>(sessionData || null);

  useEffect(() => {
    if (user) {
      return;
    }

    if (!sessionPayload) {
      return setUser(null);
    }

    const userData = sessionPayload.strapiUser;
    if (!userData) {
      return setUser(null);
    }

    const newUser = new UserImpl({
      id: userData.id,
      username: userData.username,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      name: userData.name,
      company: userData.company,
      role: {
        name: userData.role
      },
      blocked: userData.blocked,
      country: userData.country,
      customer: userData.customer as CustomerData,
      defaultRetailer: userData.defaultRetailer as RetailerData
    });

    setUser(newUser);
  }, [sessionPayload]);

  return <UserContext.Provider value={{ user, sessionPayload, setSessionPayload }}>{children}</UserContext.Provider>;
};
